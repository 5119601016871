<template>
  <div class="talentsData">
    <div class="bottomCard ly-container__bg">
      <div class="ly_operation" style="margin-bottom: 10px">
        <div class="left">
          <!-- 添加案例 -->
          <el-button @click="addItem" type="primary" icon="el-icon-plus">添加角色</el-button>
        </div>
      </div>
      <!-- 表格数据 -->
      <el-table
        class="ly_table"
        :data="dataData"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{
          background: '#F5F6F7',
          color: '#363E4D',
          'text-align': 'center',
        }"
      >
        <el-table-column prop="id" label="序号" align="center" width="120">
          <template v-slot="scope">
            <span>{{(queryInfo.pageNum-1)*queryInfo.pageSize+(scope.$index + 1)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cname" label="角色名"></el-table-column>
        <el-table-column prop="introduce" label="角色描述"></el-table-column>
        <el-table-column prop="cout" label="用户数量"></el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <button-table
              @buttonClick="editTalent(scope.row)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              @buttonClick="deleteTalent(scope.row.id)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        :current-page="queryInfo.pageNumber"
        :page-sizes="[3, 5, 10]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      :title="addEditValue ? '编辑角色' : '添加角色'"
      :visible.sync="dialogVisible"
      width="30%"
      @close="handleClose"
      center
    >
      <div class="elForm">
        <el-form label-width="100px" :model="userData">
          <el-form-item label="角色名：">
            <el-input style="width: 470px" v-model="userData.cname"></el-input>
          </el-form-item>
          <el-form-item label="极限范围：">
            <el-tree
              :data="data"
              node-key="id"
              show-checkbox
              :props="defaultProps"
              default-expand-all
              ref="tree"
              :default-checked-keys="resourceCheckedKey"
              @check-change="handleCheckData"
            ></el-tree>
          </el-form-item>
          <el-form-item label="权限描述：">
            <el-input
              :autosize="{ minRows: 4, maxRows: 20 }"
              type="textarea"
              style="width: 470px"
              v-model="userData.introduce"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addEditUser">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script src="../../assets/font/iconfont.js"></script>
<script>
import ButtonTable from '../../components/ButtonTable.vue'
import { getRoles, getLimit, deleteRole, addRole } from '@/api/system.js'
export default {
  components: {
    ButtonTable,
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem('token')
    },
  },
  created() {
    this.getLimitAll()
    this.getSchemes()
  },
  data() {
    return {
      // 查询搜索数据
      // 表格数据
      addEditValue: false,
      queryInfo: {
        pageSize: 10,
        pageNum: 1,
      },
      userData: {
        couMenu: [],
        cname: '',
        introduce: '',
      },
      resourceCheckedKey: [],
      dataData: [],
      total: 0,
      // 搜索
      input2: '',
      // 删除
      deleteTalentData: [],
      multipleSelection: [],
      // 弹出框
      dialogVisible: false,
      data: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
    }
  },
  methods: {
    async getLimitAll() {
      const res = await getLimit()
      this.data = res.data
    },
    async getSchemes() {
      const res = await getRoles(this.queryInfo)
      if (res.code === 200) {
        this.dataData = res.data.list
        this.total = res.data.total
      }
    },
    // 分页
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getSchemes()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getSchemes()
    },
    // 添加用户
    addItem() {
      this.dialogVisible = true
      this.addEditValue = false
    },
    async addEditUser() {
      Object.assign(this.userData, {
        Authorization: this.Authorization,
      })
      if (
        this.userData.cname == '' ||
        this.userData.introduce == '' ||
        this.userData.couMenu == null
      ) {
        this.$message.error('请完整信息！')
      } else {
        this.userData.couMenu = this.$refs.tree.getCheckedKeys()
        const res = await addRole(this.userData)
        if (res.code == 200) {
          if (this.addEditValue) {
            this.$message.success('编辑成功')
          } else {
            this.$message.success('添加成功')
          }
          this.dialogVisible = false
          this.getSchemes()
          this.addEditValue = true
        } else {
          this.$message.error('添加失败')
        }
      }
    },
    // 跳转编辑页面
    editTalent(data) {
      this.dialogVisible = true
      this.addEditValue = true
      this.userData = data
      this.resourceCheckedKey = data.couMenu
      // this.$refs.tree.setCheckedKeys();
    },
    // 单个删除
    deleteTalent(deleteID) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示').then(
        async () => {
          const res = await deleteRole({ id: deleteID })
          if (res.code == 200) {
            this.getSchemes()
            this.$message.success('删除成功')
          }
        }
      )
    },
    handleClose() {
      this.dialogVisible = false
      this.userData = {
        cname: '',
        introduce: '',
        couMenu: [],
      }
      this.$refs.tree.setCheckedKeys([])
    },
    formatString(val) {
      if (!val) {
        return []
      } else {
        return val.split(',')
      }
    },
    async btnClick(data) {
      const res = await addEditUsers(data)
      this.$message.success('修改成功')
      this.getSchemes()
    },
    handleCheckData() {
      let res = this.$refs.tree.getCheckedNodes()
      let arr = []
      res.forEach((item) => {
        arr.push(item.id)
      })
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-card__body {
  padding: 10px;
}
// 下半el-card部分
.left {
  display: flex;
  align-items: center;
}
/deep/ .el-input-group__append {
  background: #1e9fff;
  color: #fff;
  border: 1px solid #1e9fff;
}
/deep/ .upload {
  padding: 12px 20px;
}
/deep/ .el-dialog__header {
  text-align: center;
  font-weight: 700;
}
/deep/ .el-dialog {
  // height: 700px;
  // overflow: auto;
  width: 50% !important;
}
.elForm {
  height: 500px;
  overflow: auto;
}
</style>
