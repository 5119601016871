import request from '@/utils/request'

// 各模块权限一览
export function getLimit(data) {
  return request({
    url: '/system/Menu/getMenuAll',
    method: 'get',
    params: data
  })
}

// 用户查询
export function getUser(data) {
  return request({
    url: '/system/User/getUserPageList',
    method: 'post',
    data
  })
}

// 用户新增修改
export function addEditUsers(data) {
  return request({
    url: '/system/User/addUser',
    method: 'post',
    data
  })
}

// 用户删除
export function deleteUser(data) {
  return request({
    url: '/system/User/deleteUser',
    method: 'get',
    params: data
  })
}

// 角色下来菜单
export function getRole(data) {
  return request({
    url: '/system/Role/getRoleAll',
    method: 'post',
    data
  })
}

//角色管理
// 角色一览
export function getRoles(data) {
  return request({
    url: '/system/Role/getRolePageList',
    method: 'post',
    data
  })
}

// 添加修改角色
export function addRole(data) {
  return request({
    url: '/system/Role/addUpateRole',
    method: 'post',
    data
  })
}

// 删除角色角色
export function deleteRole(data) {
  return request({
    url: '/system/Role/DeleteRoleByid',
    method: 'get',
    params: data
  })
}

// 查询操作日志
export function getNamedate(data) {
  return request({
    url: '/system/Log/getNamedatePageList',
    method: 'post',
    data
  })
}

// 查询数据字典
export function getDataCode(data) {
  return request({
    url: '/system/code/getNameCodeList',
    method: 'post',
    data
  })
}

// 新增修改数据字典
export function addeditDataCode(data) {
  return request({
    url: '/system/code/addupdateCodeMasert',
    method: 'post',
    data
  })
}

// 查询标签类型
export function getCodeComboBox() {
  return request({
    url: '/system/code/getCodeComboBox',
    method: 'get',
  })
}

// 删除标签
export function deleteCodeMasert(data) {
  return request({
    url: '/system/code/deleteCodeMasert',
    method: 'get',
    params: data
  })
}
