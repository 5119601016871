<template>
  <div class="buttonTable">
    <!-- 使用 el-tooltip 组件来显示一个工具提示，提示的内容由 content prop 提供。 -->
    <!-- 在 el-tooltip 内部，有一个 el-button 组件，这是一个文本类型的按钮，大小为小。 -->
    <el-tooltip effect="light" :content="content" placement="bottom">
      <!--  el-button 组件上有一个点击事件监听器 @click.native="btnClick"，但这里的 .native 修饰符其实是不必要的，
        因为 btnClick 是在组件内部定义的方法，而不是原生 DOM 事件。
        在 el-button 内部，有一个 i 元素，其类名由 icon prop 提供，用于显示图标 -->
      <el-button type="text" size="small" @click.native="btnClick">
        <i :class="icon" />
      </el-button>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  // props 定义了 content 和 icon 两个属性，它们都是字符串类型，并且都是必须的（但注意，require 应该是 required）。
  props: {
    content: {
      type: String,
      require: true,
    },
    icon: {
      type: String,
      require: true,
    },
  },
  name: "ButtonTable",

  methods: {
    // 组件导出了一个对象，定义了该组件的属性（props）和方法（methods）。
    // methods 定义了 btnClick 方法，当按钮被点击时，该方法会被调用，并通过 this.$emit('buttonClick') 触发一个名为 buttonClick 的自定义事件。
    btnClick() {
      this.$emit("buttonClick");
    },
  },
};
</script>

<style>
.buttonTable {
  display: inline-block;
}
</style>
